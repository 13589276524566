import React, { useState, useEffect } from "react";
import "./App.css";
import beerImage from "./modelo.png";
import hat from "./hat.png";
import { FaMoon, FaSun } from "react-icons/fa";
import { HiMiniComputerDesktop } from "react-icons/hi2";
import { Listbox } from "@headlessui/react";
import { IconContext } from "react-icons";
import dk from "./dk.svg";
import es from "./es.svg";

function App() {
  const [showHat, setShowHat] = useState(false);
  const [isRaining, setIsRaining] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "system");
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "da"
  );

  useEffect(() => {
    // Apply the theme based on the current state
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else if (theme === "light") {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    } else {
      // For system theme
      localStorage.removeItem("theme");
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    }
  }, [theme]);
  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  useEffect(() => {
    let beerInterval;

    if (isRaining) {
      setShowHat(true); // Show the hat when beer rain starts
      beerInterval = setInterval(createBeer, 100); // Create beers at regular intervals
    }

    // Set a timer to stop the beer rain and remove all beers after 10 seconds
    const timer = setTimeout(() => {
      if (isRaining) {
        clearInterval(beerInterval); // Stop new beers from spawning
        removeBeers(); // Remove all beers
        setIsRaining(false); // Stop the rain
        setShowHat(false); // Hide the hat after the rain
      }
    }, 10000); // 10 seconds duration

    // Clear interval and timer when component unmounts or rain stops
    return () => {
      clearInterval(beerInterval);
      clearTimeout(timer);
    };
  }, [isRaining]);

  const startBeerRain = () => {
    setIsRaining(true); // Start the beer rain
  };

  const createBeer = () => {
    const beer = document.createElement("img");
    beer.src = beerImage;
    beer.className = "falling-beer";
    beer.style.left = Math.random() * 100 + "vw";
    beer.style.animationDuration = Math.random() * 2 + 3 + "s";
    beer.style.transform = `rotate(${Math.random() * 360}deg)`;
    document.body.appendChild(beer);
  };

  // Function to remove all beer elements
  const removeBeers = () => {
    document.querySelectorAll(".falling-beer").forEach((beer) => beer.remove());
  };

  return (
    <div className="bg-white dark:bg-slate-800 h-dvh flex items-center justify-center">
      <header className="App-header">
        <div className="fixed top-10 right-10">
          <Listbox value={theme} onChange={setTheme}>
            <Listbox.Button className="relative flex justify-center cursor-pointer rounded-lg bg-slate-100 dark:bg-slate-600 w-12 h-12 items-center text-center shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              {theme === "system" ? (
                <HiMiniComputerDesktop />
              ) : theme === "light" ? (
                <FaSun />
              ) : theme == "dark" ? (
                <FaMoon />
              ) : null}
            </Listbox.Button>
            <Listbox.Options className="gap-4 flex flex-col bg-slate-100 dark:bg-slate-600 justify-center items-center p-4 mt-2 rounded-lg">
              <Listbox.Option value="system" className="cursor-pointer">
                <HiMiniComputerDesktop />
              </Listbox.Option>
              <Listbox.Option value="light" className="cursor-pointer">
                <FaSun />
              </Listbox.Option>
              <Listbox.Option value="dark" className="cursor-pointer">
                <FaMoon />
              </Listbox.Option>
            </Listbox.Options>
          </Listbox>
        </div>
        <div className="fixed top-10 right-24">
          <Listbox value={language} onChange={setLanguage}>
            <Listbox.Button className="relative flex justify-center cursor-pointer rounded-lg bg-slate-100 dark:bg-slate-600 w-12 h-12 items-center  text-center shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              {language === "da" ? (
                <img src={dk} className="h-6 w-6" alt="danish flag" />
              ) : language === "es" ? (
                <img src={es} className="h-6 w-6" alt="spanish flag" />
              ) : null}
            </Listbox.Button>
            <Listbox.Options className="gap-4 flex flex-col bg-slate-100 dark:bg-slate-600 justify-center items-center p-3 mt-2 rounded-lg">
              <Listbox.Option value="da" className="cursor-pointer">
                <img src={dk} className="h-6 w-6" alt="danish flag" />
              </Listbox.Option>
              <Listbox.Option value="es" className="cursor-pointer">
                <img src={es} className="h-6 w-6" alt="spanish flag" />
              </Listbox.Option>
            </Listbox.Options>
          </Listbox>
        </div>
        <p className="text-slate-900 dark:text-white text-xl sm:text-4xl relative">
          Du skal have{" "}
          <button onClick={startBeerRain}>
            <span
              className={`transition-opacity duration-300 ease-in-out ${
                showHat ? "hidden" : "opacity-100 block"
              }`}
            >
              øl
            </span>
            <span
              className={`transition-opacity duration-300 ease-in-out ${
                showHat ? "visible" : "hidden"
              }`}
            >
              cerveza
            </span>
          </button>{" "}
          i dag!
          <img
            src={hat}
            className={`mexican-hat h-36 sm:h-48 absolute top-[-150px] sm:top-[-190px] ${
              showHat ? "visible" : "hidden"
            }`}
            alt="mexican hat"
          />
          {language === "es" ? (
            <span className="absolute bottom-[-30px] sm:bottom-[-50px] right-0 left-0 text-center">
              (På spansk)
            </span>
          ) : null}
        </p>
      </header>
    </div>
  );
}

export default App;
